$purple: #925ddd;
$pink: #f5f0f6;
$white: #fffefe;
$dark: #000000;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #9467e8;


$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);
}